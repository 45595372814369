<template>
	<div>
		<el-dialog :draggable="true" width="40%" @close="Reset" :destroy-on-close="true" v-model="outerVisible"
			:title="title">
			<div class="dialog">
				<el-form ref="ruleForm" label-width="80px">
					<el-form-item label="头像">
						<span class="xx"></span>
						<div class="dialog-up">
							<!-- 预览图 -->
							<img v-if="ruleForm.thumb" class="dialog-img" :src="ruleForm.thumb" />
							<p v-if="ruleForm.thumb" class="dele" @click="dele">删除</p>
							<el-upload accept=".jpg,.png" :headers="Headers" :action="ActionUrl"
								:on-success="handleAvatarSuccess">
								<div class="upload">
									<i class="icon iconfont icon-shangchuan"></i>
								</div>
							</el-upload>
						</div>
					</el-form-item>
					<el-form-item label="排序">
						<span class="xx"></span>
						<el-input clearable v-model="ruleForm.orderby" placeholder="请输入排序"></el-input>
					</el-form-item>
					<el-form-item label="姓名">
						<span class="xx">*</span>
						<el-input clearable v-model="ruleForm.name" placeholder="请输入姓名"></el-input>
					</el-form-item>
					<el-form-item label="性别">
						<span class="xx">*</span>
						<el-select clearable v-model="ruleForm.sex" placeholder="请选择性别">
							<el-option label="男" value="男"></el-option>
							<el-option label="女" value="女"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="年龄">
						<span class="xx">*</span>
						<el-input clearable v-model="ruleForm.age" placeholder="请输入年龄"></el-input>
					</el-form-item>
					<el-form-item label="家庭几口人">
						<span class="xx">*</span>
						<el-input clearable v-model="ruleForm.people_num" placeholder="请输入家庭几口人"></el-input>
					</el-form-item>
					<el-form-item label="联系方式">
						<span class="xx"></span>
						<el-input clearable v-model="ruleForm.tel" placeholder="请输入手机号码"></el-input>
					</el-form-item>
					<el-form-item label="住址">
						<span class="xx"></span>
						<el-input clearable v-model="ruleForm.address" placeholder="请输入住址"></el-input>
					</el-form-item>
				</el-form>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button size="mini" @click="outerVisible = false">取消</el-button>
					<el-button size="mini" class="dialog-btn el-white" type="primary" @click="SubmitForm">确定
					</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/important";

	export default {
		data() {
			return {
				title: '',
				outerVisible: false,
				Headers: {
					token: ''
				},
				ActionUrl: '',
				ruleForm: {
					name: '',
					village_id: '',
					sex: '',
					age: '',
					people_num: '',
					tel: '',
					address: '',
					thumb: '',
					orderby: 0,
				},
			}
		},
		mounted() {
			// 上转图片url
			this.Headers.token = sessionStorage.getItem('token')
			this.Headers.appid = sessionStorage.getItem('appid')
			this.Headers.nonce = sessionStorage.getItem('nonce')
			this.Headers.timestamp = sessionStorage.getItem('timestamp')
			this.Headers.sign =sessionStorage.getItem('sign')
			
			this.ActionUrl = sessionStorage.getItem('baseURL') + '/Upload/FormUpload'
		},
		methods: {
			// 删除图片
			dele() {
				this.ruleForm.thumb = ''
			},
			// 上转图片
			handleAvatarSuccess(res, file) {
				this.ruleForm.thumb = res.data.url
			},
			// 获取用户信息
			getuser() {
				this.$http.post(api.info, {
						id: this.ruleForm.id
					})
					.then((res) => {
						if (res.code == 200) {
							this.ruleForm = res.data
							delete this.ruleForm.create_time
						}
					})
			},
			// 清空数据
			Reset() {
				for (let key in this.ruleForm) {
					this.ruleForm[key] = ''
				}
				this.ruleForm.status = 1
				delete this.ruleForm.id
				delete this.ruleForm.delete_time
			},
			//新增/编辑
			SubmitForm() {
				if (this.ruleForm.name === '') {
					ElMessage({
						message: '请输入姓名',
						type: 'warning',
					})
				} else if (this.ruleForm.sex === '') {
					ElMessage({
						message: '请选择性别',
						type: 'warning',
					})
				} else if (this.ruleForm.age === '') {
					ElMessage({
						message: '请输入年龄',
						type: 'warning',
					})
				} else if (this.ruleForm.people_num === '') {
					ElMessage({
						message: '请输入家庭几口人',
						type: 'warning',
					})
				} else {
					if (this.title == '新增') {
						this.add()
					} else {
						this.edit()
					}

				}

			},
			// 编辑
			edit() {
				this.$http.post(api.edit, this.ruleForm)
					.then((res) => {
						if (res.code == 200) {
							ElMessage({
								type: 'success',
								message: '编辑成功',
							})
							this.$EventBus.emit('RefreshUser')
							this.outerVisible = false
							// 刷新列表
							this.$emit('SubmitForm')
						}
					})
			},
			// 新增
			add() {
				this.$http.post(api.add, this.ruleForm)
					.then((res) => {
						if (res.code == 200) {
							ElMessage({
								type: 'success',
								message: '新增成功',
							})
							this.outerVisible = false
							// 刷新列表
							this.$emit('SubmitForm')
						}
					})
			},
			// 显示隐藏
			show(val, id) {
				if (id) {
					this.ruleForm.id = id
					this.getuser()
				}
				this.title = val
				this.outerVisible = true
			}
		}
	}
</script>

<style scoped>
</style>